/**
 * Is in dev env
 */
export const IS_DEV = import.meta.env.DEV;

/**
 * Main app name
 */
export const APP_NAME = 'Antarctic Wallet';

/**
 * Default app locale
 */
export const DEFAULT_LOCALE = 'ru';

/**
 * Страница после авторизации по умолчанию
 */
export const DEFAULT_HOME = '/home';

/**
 * Страница с авторизацией
 */
export const DEFAULT_AUTH = '/auth/enter';

/**
 * Страница с welcome слайдером
 */
export const DEFAULT_ROOT = '/welcome';

/**
 * Включён ли режим отладки
 *
 * Активировать его можно разово для страницы через добавление ?debug=true в URL
 * Или же на постоянном режиме через localStorage, добавив в него параметр
 * `aw-debug` = `true`
 */
export const hasDebug = () => {
  const route = useRoute();
  const awDebug = localStorage.getItem('aw-debug');
  return route.query?.debug === 'true' || awDebug === 'true';
};

export type StatusType = 'success' | 'failed' | 'process';

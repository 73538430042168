import * as Sentry from '@sentry/vue';
import {
  HttpClient as HttpClientIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';

import type { Operation } from '@sentry/vue/types/types';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  // Если Sentry DSN не задан
  if (!sentry.dsn) {
    console.warn('Sentry DSN не задан. Инициализация Sentry не удалась.');
    return;
  }
  // Инициализация Sentry
  Sentry.init({
    // Vue app instance
    app: nuxtApp.vueApp,
    // Dsn, используемый для подключения к Sentry и идентификации проекта
    dsn: sentry.dsn,
    // Флажок, включающий отладку
    debug: IS_DEV,
    // Флажок, включающий функцию отслеживания сеансов
    autoSessionTracking: sentry.autoSessionTracking,
    // Частота дискретизации для повторов продолжительностью в сеанс
    replaysSessionSampleRate: sentry.clientConfig.replaysSessionSampleRate,
    // Частота дискретизации для сеансов, в которых произошла ошибка
    replaysOnErrorSampleRate: sentry.clientConfig.replaysOnErrorSampleRate,
    // Мониторинг производительности
    tracesSampleRate: sentry.tracing.tracesSampleRate,
    // Частота дискретизации для профилирования (относительно tracesSampleRate)
    profilesSampleRate: sentry.tracing.profilesSampleRate,
    // Решает, следует ли отслеживать компоненты, подключаясь к его методам жизненного цикла.
    // Можно задать массив имен компонентов
    trackComponents: sentry.trackComponents,
    // Список хуков, которые необходимо отслеживать в течение жизненного цикла компонента
    hooks: sentry.hooks as Operation[],
    // Список интеграций, которые должны быть установлены после инициализации SDK
    integrations: [
      new Sentry.Replay({
        // Маскировать весь текст в записях
        maskAllText: sentry.clientIntegrations.Replay.maskAllText,
        // Блокировать все медиа (например, изображения, svg, видео) в записях
        blockAllMedia: sentry.clientIntegrations.Replay.blockAllMedia,
      }),
      // Интеграция с трассировкой браузера автоматически определяет действия по загрузке
      // страницы браузера/навигации как транзакции и фиксирует запросы, показатели и ошибки
      new Sentry.BrowserTracing({
        // Инструментарий, который создает транзакции изменения маршрута
        routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
          // Что использовать для меток маршрутов
          routeLabel: sentry.tracing.vueRouterInstrumentationOptions.routeLabel as
            | 'name'
            | 'path'
            | undefined,
        }),
        // Максимальная продолжительность транзакции
        finalTimeout: sentry.tracing.browserTracing.finalTimeout,
      }),

      // Интеграция с HttpClient.
      // Создает события для неудачных HTTP-запросов на стороне клиента
      new HttpClientIntegration(),
      // Интеграция Reporting API
      new ReportingObserverIntegration(),
    ],
  });

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    /*
     * Создает новый Sentry scope и выполняет заданную операцию внутри.
     * scope автоматически удаляется после завершения операции
     * */
    Sentry.withScope((scope) => {
      scope.setExtra('context', context);
      Sentry.captureException(err);
    });
  };
  nuxtApp.hook('app:error', (error: any) => {
    // Фиксирует событие исключения и отправляет его в Sentry
    Sentry.captureException(error);
  });
});
